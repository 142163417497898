<button
  *ngIf="!entry.shouldHide"
  mat-button
  class="menu-item"
  [class.expanded]="entry.isExpanded"
  [class.active]="menuTreeViewContext.isActive(entry)"
  [class.pressed]="isPressed"
  (click)="menuItemClicked.emit($event)"
  (mousedown)="isPressed = true"
  (mouseup)="isPressed = false"
>
  <div class="flex-row flex-start-center full-width gap-6">
    <mat-icon class="icon" *ngIf="hasIcon()" [style.min-width.px]="iconWidthInPx" [svgIcon]="entry.icon"></mat-icon>
    <vdw-ellipsis-label class="text" [text]="entry.title"></vdw-ellipsis-label>
    <mat-icon *ngIf="hasChildEntries()" [style.min-width.px]="iconWidthInPx" class="arrow-right" [svgIcon]="entry.isExpanded ? 'solid-expand-more' : 'solid-keyboard-arrow-right'"></mat-icon>
    <div *ngIf="!hasChildEntries() && hasLink()" class="star-container" [class.favorited]="entry.isFavorited">
      <mat-icon
        (click)="favoriteClicked($event)"
        [style.min-width.px]="iconWidthInPx"
        [style.max-width.px]="iconWidthInPx"
        class="star-icon"
        [svgIcon]="entry.isFavorited ? 'solid-star' : 'solid-star-rate'"
      ></mat-icon>
    </div>
  </div>
</button>
