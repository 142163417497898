import {
  AgGridEvent,
  CellClassFunc,
  CellClassRules,
  CellClickedEvent,
  CellRendererSelectorFunc,
  CellStyleFunc,
  ColDef,
  DndSourceOnRowDragParams,
  EditableCallbackParams,
  GetQuickFilterTextParams,
  HeaderClass,
  HeaderValueGetterFunc,
  ICellRendererParams,
  ITooltipParams,
  RowDragCallback,
  RowNode,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams
} from 'ag-grid-community';
import {L10nIntlService} from 'angular-l10n';
import moment from 'moment';
import {forkJoin, map, Observable} from 'rxjs';
import {Unit} from '../common/unit.enum';
import {AssertionUtils} from '../common/utils/assertion-utils';
import {LocaleUtils} from '../common/utils/locale-utils';
import {TranslateService} from '../translation/translate.service';
import {AgGridUtils} from './helper/ag-grid-utils';
import {MobileColDef} from './mobile-col-def.interface';

export class ColDefBuilder {
  private colDef: ColDef = {};

  public constructor(
    private readonly l10nIntlService: L10nIntlService,
    private readonly translate: TranslateService
  ) {}

  public build(): ColDef {
    return this.colDef;
  }

  public withColId(colId: string): this {
    this.colDef.colId = colId;
    return this;
  }

  public withField(field: string, withTooltip: boolean = false): this {
    this.colDef.field = field;
    if (withTooltip) {
      this.colDef.tooltipField = field;
    }
    return this;
  }

  public withHeaderValueGetter(headerValueGetter: string | HeaderValueGetterFunc): this {
    this.colDef.headerValueGetter = headerValueGetter;
    return this;
  }

  public withHeaderCheckboxSelection(value: boolean = true): this {
    this.colDef.headerCheckboxSelection = value;
    return this;
  }

  public withHeaderName(headerName: string, count: number = 1, isNumberOf: boolean = false, unit?: string, unitCount: number = 1): this {
    const headerNameTranslation = (isNumberOf ? '# ' : '') + this.translate.instant(headerName, {count}) + (unit ? ` (${this.translate.instant(unit, {count: unitCount})})` : '');
    this.colDef.headerName = headerNameTranslation;
    this.colDef.headerTooltip = headerNameTranslation;
    return this;
  }

  public withoutHeaderName(): this {
    this.colDef.headerComponentParams = {
      ...this.colDef.headerComponentParams,
      showName: false
    };
    return this;
  }

  public withPinned(pinned: 'left' | 'right' | boolean): this {
    this.colDef.pinned = pinned;
    return this;
  }

  public withLockPinned(lockPinned: boolean): this {
    this.colDef.lockPinned = lockPinned;
    return this;
  }

  public withLockPosition(lockPosition: boolean | 'left' | 'right'): this {
    this.colDef.lockPosition = lockPosition;
    return this;
  }

  public withLockVisible(lockVisible: boolean = true): this {
    this.colDef.lockVisible = lockVisible;
    return this;
  }

  public withInitialHide(initialHide: boolean = false): this {
    this.colDef.initialHide = initialHide;
    return this;
  }

  public withMaxWidth(maxWidth: number): this {
    this.colDef.maxWidth = maxWidth;
    return this;
  }

  public withMinWidth(minWidth: number): this {
    this.colDef.minWidth = minWidth;
    return this;
  }

  public withWidth(width: number): this {
    this.colDef.width = width;
    return this;
  }

  public withHide(hide: boolean = true): this {
    this.colDef.hide = hide;
    return this;
  }

  public withHiddenInToolPanel(hide: boolean = true): this {
    this.colDef.suppressColumnsToolPanel = hide;
    return this;
  }

  public withHeaderClass(headerClass: HeaderClass): this {
    this.colDef.headerClass = headerClass;
    return this;
  }

  public withHeaderComponent(headerComponent: any, headerComponentParams: any = null): this {
    this.colDef.headerComponent = headerComponent;
    this.colDef.headerComponentParams = headerComponentParams;
    return this;
  }

  public withAutoHeaderHeight(): this {
    this.colDef.autoHeaderHeight = true;
    return this;
  }

  public withCellClass(cellClass: string | string[] | CellClassFunc): this {
    this.colDef.cellClass = cellClass;
    return this;
  }

  public withCellStyle(cellStyle: {[cssProperty: string]: string} | CellStyleFunc): this {
    this.colDef.cellStyle = cellStyle;
    return this;
  }

  public withCellClassRules(cellClassRules: CellClassRules): this {
    this.colDef.cellClassRules = cellClassRules;
    return this;
  }

  public withCellRenderer(cellRenderer: any, cellRendererParams: any = null, autoHeight: boolean = false): this {
    this.colDef.cellRenderer = cellRenderer;
    this.colDef.cellRendererParams = cellRendererParams;
    this.colDef.autoHeight = autoHeight;

    if (Array.isArray(this.colDef.cellClass)) {
      this.colDef.cellClass = [...this.colDef.cellClass, 'clip-text-overflow'];
    } else if (AssertionUtils.isString(this.colDef.cellClass)) {
      this.colDef.cellClass = [this.colDef.cellClass, 'clip-text-overflow'];
    } else {
      this.colDef.cellClass = 'clip-text-overflow';
    }

    return this;
  }

  public withCellRendererSelector(cellRendererSelector: CellRendererSelectorFunc<any>): this {
    this.colDef.cellRendererSelector = cellRendererSelector;
    return this;
  }

  public withEditable(editable: boolean | ((params: EditableCallbackParams) => boolean) = true): this {
    this.colDef.editable = editable;
    return this;
  }

  public withCellEditor(cellEditor: any, cellEditorParams: any = null): this {
    this.colDef.cellEditor = cellEditor;
    this.colDef.cellEditorParams = cellEditorParams;
    return this;
  }

  public withRowDragText(rowDragText: any): this {
    this.colDef.rowDragText = rowDragText;
    return this;
  }

  public withRowDrag(rowDrag: boolean | RowDragCallback): this {
    this.colDef.rowDrag = rowDrag;

    return this;
  }

  public withFlex(flex: number = 1): this {
    this.colDef.flex = flex;
    return this;
  }

  public withMobile(): this {
    (this.colDef as MobileColDef).mobile = true;
    return this;
  }

  public withDndSource(): this {
    this.colDef.dndSource = true;
    return this;
  }

  public withDndSourceOnRowDrag(dnd: (params: DndSourceOnRowDragParams) => void): this {
    this.colDef.dndSourceOnRowDrag = dnd;
    return this;
  }

  // Functionality
  public withRowGroup(rowGroup: boolean = true, enableRowGroup: boolean = false): this {
    this.colDef.rowGroup = rowGroup;
    this.colDef.enableRowGroup = enableRowGroup;
    return this;
  }

  public withCheckboxSelection(checkboxSelection: boolean = true): this {
    this.colDef.checkboxSelection = checkboxSelection;
    return this;
  }

  public withShowDisabledCheckboxes(): this {
    this.colDef.showDisabledCheckboxes = true;
    return this;
  }

  public withSuppressHeaderMenuButton(suppressHeaderMenuButton: boolean = true): this {
    this.colDef.suppressHeaderMenuButton = suppressHeaderMenuButton;
    return this;
  }

  public withSuppressSizeToFit(suppressSizeToFit: boolean = true): this {
    this.colDef.suppressSizeToFit = suppressSizeToFit;
    return this;
  }

  public withTooltipField(tooltipField: string): this {
    this.colDef.tooltipField = tooltipField;
    return this;
  }

  public withTooltipFieldForHeader(headerName: string, unit?: string): this {
    const headerNameTranslation = this.translate.instant(headerName) + (unit ? ` (${this.translate.instant(unit)})` : '');
    this.colDef.headerTooltip = headerNameTranslation;
    return this;
  }

  public withTooltipValueGetter(tooltipValueGetter: (params: ITooltipParams) => string): this {
    this.colDef.tooltipValueGetter = tooltipValueGetter;
    return this;
  }

  public withValueGetter(valueGetter: string | ((params: ValueGetterParams<any>) => any), withTooltip: boolean = false): this {
    this.colDef.valueGetter = valueGetter;
    if (withTooltip) {
      this.withTooltipValueGetter((params: ITooltipParams) => (Array.isArray(params.value) ? params.value.join(', ') : params.value));
    }
    return this;
  }

  public withValueFormatter(valueFormatter: string | ((params: ValueFormatterParams<any>) => string)): this {
    this.colDef.valueFormatter = valueFormatter;
    return this;
  }

  public withComparator(comparator: (valueA: any, valueB: any, nodeA: RowNode, nodeB: RowNode, isInverted: boolean) => number): this {
    this.colDef.comparator = comparator;
    return this;
  }

  public withSortable(sortable: boolean = true): this {
    this.colDef.sortable = sortable;
    return this;
  }

  public withResizable(resizable: boolean = true): this {
    this.colDef.resizable = resizable;
    return this;
  }

  public withValueSetter(valueSetter: string | ((params: ValueSetterParams<any>) => boolean)): this {
    this.colDef.valueSetter = valueSetter;
    return this;
  }

  // Event handlers
  public withOnCellClicked(onCellClicked: (event: CellClickedEvent) => void): this {
    return this.withOnEvent('onCellClicked', onCellClicked);
  }

  // Additional logic
  public withInput(numeric: boolean = false): this {
    this.colDef.cellClass = numeric ? 'right' : '';
    return this.withSuppressHeaderMenuButton();
  }

  // Filter
  public withGetQuickFilterText(getQuickFilterText: (params: GetQuickFilterTextParams) => string): this {
    this.colDef.getQuickFilterText = getQuickFilterText;
    return this;
  }

  public withSetColumnFilterOnly(): this {
    this.colDef.filter = 'agSetColumnFilter';
    return this;
  }

  public withTextMultiFilterCellRenderer(cellRenderer: any): this {
    this.colDef.filterParams = {
      filters: [
        {
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset']
          }
        },
        {
          filter: 'agSetColumnFilter',
          filterParams: {
            cellRenderer,
            buttons: ['reset']
          }
        }
      ]
    };

    return this;
  }

  public withTextMultiFilter(filterValueGetter?: Observable<string[] | number[]> | Observable<string[] | number[]>[], conversionRate?: number, showTooltips?: boolean): this {
    this.colDef.filterParams = {
      filters: [
        {
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset']
          }
        }
      ]
    };

    if (!AssertionUtils.isNullOrUndefined(filterValueGetter)) {
      this.colDef.filterParams.filters[0].values = (params: any): any => this.getPossibleValuesAsync(params, filterValueGetter, conversionRate);
      this.colDef.filterParams.filters.push({
        filter: 'agSetColumnFilter',
        filterParams: {
          buttons: ['reset'],
          showTooltips: showTooltips,
          values: (params: any): any => this.getPossibleValuesAsync(params, filterValueGetter, conversionRate)
        }
      });
    }

    return this;
  }

  public withBooleanFilter(translatedFalse: string, translatedTrue: string): this {
    this.colDef.filterParams = {
      filters: [
        {
          filter: 'agSetColumnFilter',
          filterParams: {
            suppressSelectAll: true,
            values: [true, false],
            valueFormatter: (params: ValueFormatterParams): string => (params.value === true ? translatedTrue : translatedFalse)
          }
        }
      ]
    };
    return this;
  }

  public withNumericMultiFilter(
    filterValueGetter?: Observable<string[] | number[]>,
    conversionRate?: number | (() => number),
    l10nIntlService?: L10nIntlService,
    valueFormatter?: (value: number) => string
  ): this {
    const values = !AssertionUtils.isNullOrUndefined(filterValueGetter) ? (params: any): any => this.getPossibleValuesAsync(params, filterValueGetter, conversionRate, l10nIntlService) : undefined;

    this.colDef.filterParams = {
      filters: [
        {
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            values
          }
        },
        {
          filter: 'agSetColumnFilter',
          filterParams: {
            buttons: ['reset'],
            values,
            comparator: (a: any, b: any): number => {
              const valA = parseInt(a);
              const valB = parseInt(b);
              if (valA === valB) {
                return 0;
              }
              return valA > valB ? 1 : -1;
            },
            valueFormatter: !AssertionUtils.isNullOrUndefined(valueFormatter) ? (params: ValueFormatterParams): string => valueFormatter(params.value) : undefined
          }
        }
      ]
    };
    return this;
  }

  public withDateMultiFilter(filterValueGetter?: Observable<string[] | number[]>, conversionRate?: number): this {
    let values: any;

    if (AssertionUtils.isNullOrUndefined(filterValueGetter)) {
      this.withFilterValueGetter((params: ValueGetterParams) =>
        AssertionUtils.isNullOrUndefined(params.data[params.colDef.field]) ? null : moment(params.data[params.colDef.field]).set({hour: 0, minute: 0, second: 0, millisecond: 0}).format()
      );
    } else {
      values = (params: any): any => this.getPossibleValuesAsync(params, filterValueGetter, conversionRate);
    }

    const filterParams = {
      values,
      valueFormatter: (params: ValueFormatterParams): string => (AssertionUtils.isNullOrUndefined(params.value) ? null : moment(params.value).format('DD/MM/YYYY'))
    };

    this.colDef.filterParams = {
      filters: [
        {
          filter: 'agDateColumnFilter',
          filterParams: {
            buttons: ['reset'],
            ...filterParams,
            comparator: (dateA: any, dateB: any): number => {
              if (moment(dateB).isSame(dateA, 'date')) {
                return 0;
              } else if (moment(dateB).isAfter(dateA, 'date')) {
                return 1;
              }
              return -1;
            }
          }
        },
        {
          filter: 'agSetColumnFilter',
          filterParams: {
            buttons: ['reset'],
            ...filterParams
          }
        }
      ]
    };
    return this;
  }

  public withMultiFilter(): this {
    this.colDef.filter = 'agMultiColumnFilter';
    return this;
  }

  public withoutFilter(): this {
    this.colDef.menuTabs = ['generalMenuTab', 'columnsMenuTab'];
    this.colDef.filter = false;
    this.colDef.floatingFilter = false;
    this.colDef.sortable = false;
    return this;
  }

  public withFilterValueGetter(filterValueGetter: string | ((params: ValueGetterParams) => any)): this {
    this.colDef.filterValueGetter = filterValueGetter;
    return this;
  }

  // Composite methods
  public withDate(): this {
    return this.withTooltipValueGetter((params: ITooltipParams) => (params.value ? AgGridUtils.buildAgGridCellDateAsDDMMYYYY(params.value) : '')).withCellRenderer((params: ICellRendererParams) =>
      params.getValue() ? AgGridUtils.buildAgGridCellDateAsDDMMYYYY(params.getValue()) : ''
    );
  }

  public withMMConversion(unit: Unit = Unit.CENTIMETER, withUnitConversion: boolean = true, decimals?: number): this {
    return this.withCellRenderer((params: ICellRendererParams) =>
      withUnitConversion
        ? AgGridUtils.buildAgGridCellTextWithUnitConversion(params.getValue(), Unit.MILLIMETER, unit, this.l10nIntlService, decimals)
        : AgGridUtils.buildAgGridCellTextWithoutUnit(params.getValue(), Unit.MILLIMETER, unit, this.l10nIntlService, decimals)
    ).withTooltipValueGetter((params: ITooltipParams) =>
      withUnitConversion
        ? AgGridUtils.buildAgGridCellTooltipWithUnitConversion(params.value, Unit.MILLIMETER, unit, this.l10nIntlService, true, decimals)
        : AgGridUtils.buildAgGridCellTooltipWithUnitConversion(params.value, Unit.MILLIMETER, unit, this.l10nIntlService, false, decimals)
    );
  }

  public withSquareMMConversion(unit: Unit = Unit.SQUARE_CENTIMETER, decimals?: number): this {
    return this.withCellRenderer((params: ICellRendererParams) =>
      AgGridUtils.buildAgGridCellTextWithoutUnit(params.getValue(), Unit.SQUARE_MILLIMETER, unit, this.l10nIntlService, decimals)
    ).withTooltipValueGetter((params: ITooltipParams) => AgGridUtils.buildAgGridCellTooltipWithUnitConversion(params.value, Unit.SQUARE_MILLIMETER, unit, this.l10nIntlService, false, decimals));
  }

  public withColIdAndField(colIdAndField: string, withTooltip: boolean = false): this {
    return this.withColId(colIdAndField).withField(colIdAndField, withTooltip);
  }

  public withHeaderNameAndClass(headerName: string, headerClass: HeaderClass): this {
    return this.withHeaderName(headerName).withHeaderClass(headerClass);
  }

  public withRightAlignment(): this {
    return this.withCellClass('right');
  }

  public withVerticalCenterAlignment(rowHeight: number): this {
    this.colDef.cellStyle = {'line-height': `${rowHeight}px`};
    return this;
  }

  public withSuppressMovable(lockPosition: 'left' | 'right' = 'left'): this {
    this.colDef.suppressMovable = true;
    this.colDef.pinned = lockPosition;
    this.colDef.lockPinned = true;
    return this;
  }

  private withOnEvent(eventHandlerName: string, eventHandler: (event: AgGridEvent<any>) => void): this {
    const existingEventHandler = this.colDef[eventHandlerName];

    this.colDef[eventHandlerName] = (event: AgGridEvent<any>): void => {
      if (existingEventHandler) {
        existingEventHandler(event);
      }
      eventHandler(event);
    };

    return this;
  }

  private getPossibleValuesAsync(
    params: any,
    filterValueGetter?: Observable<string[] | number[]> | Observable<string[] | number[]>[],
    conversionRate?: number | (() => number),
    l10nIntlService?: L10nIntlService
  ): void {
    let request = filterValueGetter as Observable<string[] | number[]>;

    if (Array.isArray(filterValueGetter) && filterValueGetter.length > 0) {
      request = forkJoin(filterValueGetter).pipe(map((results: string[][]) => results.flat()));
    }

    request.subscribe((values: string[] | number[]) => {
      const convertedValues = [];
      if (conversionRate) {
        values.forEach((value: any) => {
          const conversionResult = value * (typeof conversionRate === 'number' ? conversionRate : conversionRate());
          convertedValues.push(l10nIntlService ? LocaleUtils.formatNumber(conversionResult, l10nIntlService) : conversionResult);
        });
        params.success(convertedValues);
      } else {
        params.success(values);
      }
    });
  }
}
