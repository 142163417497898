export enum ReportItemTemplateField {
  MACHINE_EFFICIENCY_VALUE = 1,
  MACHINE_EFFICIENCY_LEVEL = 2,
  OPERATOR_EFFICIENCY = 3,
  ACTUAL_ROTATIONS_PER_MINUTE = 4,
  ORDER_NAME = 5,
  ORDER_PROGRESS = 6,
  PRODUCTION_TIME = 7,
  REMAINING_TIME = 8,
  REMAINING_PICKS = 9,
  DECLARATION_CODE = 10,
  DECLARATION_ABBREVIATION = 11,
  DECLARATION_COLOR = 12,
  DECLARATION_PATTERN = 13,
  PICKS = 14,
  STANDARD_RPM = 15,
  WEAVE_PRODUCT = 16,
  AVERAGE_RPM = 17,
  QUALITY = 18,
  SPEED_EFFICIENCY = 19,
  PRODUCTION = 20,
  RUN_TIMESTAMP = 21,
  STOP_TIMESTAMP = 22,
  PICKS_REQUIRED = 23,
  PICKS_PRODUCED = 24,
  QTY_REQUIRED = 25,
  QTY_PRODUCED = 26,
  STOP_GROUPS = 27,
  GROUP_EFFICIENCY_VALUE = 28,
  ESTIMATED_TIME_OF_COMPLETION = 29,
  MACHINE_STATE = 30,
  LOT_ID = 31,
  WEIGHT = 32,
  PACKAGES = 33,
  PRODUCTIVE_SPEED = 34,
  SPINDLE_EFFICIENCY = 35,
  SYSTEM_EFFICIENCY = 36,
  CUTS_PER_10KM = 37,
  JOINTS_PER_10KM = 38,
  YARN_FAULTS_PER_10KM = 39,
  GROUP = 40,
  OVERALL_EFFICIENCY = 41,
  BATCH_NUMBER = 42,
  REMAINING_BATCH_TIME = 43,
  TOTAL_ALARM_TIME = 44,
  TOTAL_STOP_TIME = 45,
  WATER_CONSUMPTION = 46,
  STEAM_CONSUMPTION = 47,
  TEMP_MAIN_TANK = 48,
  TEMP_SIDE_TANK = 49,
  ACTIVE_MAIN_FUNCTION = 50,
  ACTIVE_FUNCTION_WINDOW_1 = 51,
  ACTIVE_FUNCTION_WINDOW_2 = 52,
  ACTIVE_FUNCTION_WINDOW_3 = 53,
  TIME_TILL_NEXT_OPERATOR_CALL = 54,
  TIME_TILL_NEXT_PREPARATION = 55,
  PROCESS_NUMBER = 56,
  ACTUAL_TREATMENT_NUMBER = 57,
  NEXT_BATCH_NUMBER = 58,
  BATCH_LOAD = 59,
  LIQUOR_RATIO = 60,
  REEL_SPEED = 61,
  PUMP_SPEED = 62,
  ACTIVE_PREPARATION = 63,
  ACTIVE_PREPARATION_STEP_NUMBER = 64,
  DYEING_POWER_CONSUMPTION = 65,
  DECLARATION_TIME = 66,
  AIR_CONSUMPTION = 67,
  POWER_CONSUMPTION = 68,
  SEC_AIR = 69,
  SEC_POWER = 70,
  AUTOMATIC_STOP = 71,
  AUTOMATIC_STOP_TIME = 72,
  AUTOMATIC_STOP_TEXT = 73,
  TUFT_PRODUCT = 74,
  STITCHES = 75,
  QTY_REMAINING = 76,
  TOTAL_RUN_TIME = 77,
  TOTAL_STOP_GROUP_TIME = 78,
  TOTAL_DECLARATION_TIME = 79,
  DECLARATIONS = 80,
  REACTIVE_ENERGY_CONSUMPTION = 81,
  APPARENT_ENERGY_CONSUMPTION = 82,
  CAPACITIVE_ENERGY = 83,
  INDUCTIVE_ENERGY = 84,
  FREQUENCY = 85,
  U12 = 86,
  U31 = 87,
  U23 = 88,
  CUSTOMER = 89,
  COLOR = 90,
  PRODUCT_GROUP = 91,
  PRODUCT_UOM = 92,
  PRODUCT_SPEED = 93,
  PRODUCT_EFFICIENCY = 94,
  PRODUCT_MOUNT_TIME = 95,
  PRODUCT_DISMOUNT_TIME = 96,
  PRODUCT_LOAD_TIME = 97,
  PRODUCT_UNLOAD_TIME = 98,
  DUE_DATE = 99,
  MOUNT_TIME = 100,
  DISMOUNT_TIME = 101,
  LOAD_TIME = 102,
  UNLOAD_TIME = 103,
  AVAILABILITY_LOSS_PERCENT = 104,
  AVAILABILITY_LOSS_TIME = 105,
  PERFORMANCE_LOSS_PERCENT = 106,
  PERFORMANCE_LOSS_TIME = 107,
  QUALITY_LOSS = 108,
  OEE_PERCENT = 109,
  MEAN_TIME_BETWEEN_FAILURE = 110,
  MEAN_TIME_TO_REPAIR = 111,
  TOOL_NAME = 501,
  TOOL_TYPE = 502,
  TOOL_WEIGHT = 503,
  TOOL_SPEED = 504,
  TOOL_STATUS = 505,
  NUMBER_CAVITIES = 506,
  USED_CAVITIES = 507,
  MOUNT_TIME_TOOL = 508,
  DISMOUNT_TIME_TOOL = 509,
  WAIVE_CYCLE = 510,
  PIECE_CYCLE = 511,
  PLASTIC_PRODUCT = 512,
  MATERIAL_PLASTICS = 513,
  ORDER_PLASTICS = 514,
  ORDER_TOOL = 515,
  REJECT_NAME = 517,
  REJECT_CODE = 518,
  REJECT_GROUP = 519,
  SCRAP_INDICATOR = 520,
  CYCLES_DONE = 521,
  ITEMS_PRODUCED = 522,
  CYCLES_WAIVED = 523,
  ITEMS_REJECTED = 524,
  MEAN_CYCLE_TIME = 525,
  MEAN_TIME_TO_ATTEND = 526,

  NONE = 9999
}
