import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DialogBuilderFactoryService} from '../../../dialogs/dialog-builder/dialog-builder-factory.service';
import {GridOptionsBuilderFactoryService} from '../../../grids/grid-options-builder-factory.service';
import {CrudOverviewDataBuilder} from '../crud-overview-data-builder';
import {CrudOverviewDataPageComponent} from '../crud-overview-data-page/crud-overview-data-page.component';
import {CrudOverviewDataConfig} from '../interfaces/crud-overview-data-config.interface';

@Injectable({
  providedIn: 'root'
})
export class CrudOverviewDataBuilderFactoryService<T = unknown> {
  public constructor(
    private readonly dialogBuilderFactory: DialogBuilderFactoryService,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService
  ) {}

  public getBuilder<TBuilder extends T = T>(): CrudOverviewDataBuilder<TBuilder> {
    return new CrudOverviewDataBuilder<TBuilder>(this.gridOptionsBuilderFactoryService);
  }

  public openObjectSelectionDialog<TDialog extends T = T>(config: CrudOverviewDataConfig<TDialog>): Observable<TDialog[]> {
    return this.dialogBuilderFactory.getBuilder().withWidth('80vw').withMinWidth(900).withHeight('80vh').withMinHeight(480).openDialog(CrudOverviewDataPageComponent, {config});
  }
}
