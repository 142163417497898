<div class="container" [class.border-hovered]="applicationLayoutService.resizeHovered">
  <div #accountSwitch>
    <vdw-account-switch
      [class.active]="accountSwitcherActive"
      class="account-switch"
      (click)="accountSwitcherClicked()"
      [name]="subscriptionName"
      [id]="subscriptionId"
      [showExpanded]="true"
    ></vdw-account-switch>
  </div>
  <div class="menu-tree-view-container flex-column gap-24" [class.dialog]="isDialog">
    <vdw-menu-tree-view
      *ngFor="let entry of applicationLayoutService.menuItems; let idx = index"
      [entry]="entry"
      [isRoot]="true"
      [isFavorites]="idx === 0"
      [iconWidthInPx]="SIDEBAR_ICON_WIDTH_IN_PX"
      [class.no-display]="idx === 0 && applicationLayoutService.hideFavorites"
    ></vdw-menu-tree-view>
    <div #processItem>
      <vdw-menu-item *ngIf="processEntry && !processEntry.shouldHide" class="process-item" [entry]="processEntry" (menuItemClicked)="processClicked()"></vdw-menu-item>
    </div>
  </div>
</div>
