import {ColDef, GridOptions} from 'ag-grid-community';
import {Observable} from 'rxjs';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {GridOptionsBuilder} from '../../grids/grid-options-builder';
import {GridOptionsBuilderFactoryService} from '../../grids/grid-options-builder-factory.service';
import {CrudOverviewButtonConfig} from './interfaces/crud-overview-button-config.interface';
import {CrudOverviewDataConfig} from './interfaces/crud-overview-data-config.interface';
import {CrudOverviewExtraActionConfig} from './interfaces/crud-overview-extra-action-config.interface';

export class CrudOverviewDataBuilder<T> {
  private config: CrudOverviewDataConfig<T> = {onlyShowSelected: false};
  private rowActionButtons: CrudOverviewButtonConfig[] = [];

  public constructor(private readonly gridOptionsBuilderFactory: GridOptionsBuilderFactoryService) {
    this.config.buttons = [];
    this.config.extraActions = [];
    this.config.selectedObjects = [];
    this.config.isObjectSelection = false;
  }

  public build(): CrudOverviewDataConfig<T> {
    return this.config;
  }

  public withGridOptions(colDefs: ColDef[], gridIdentifier: string, data: T[], getOptions?: (builder: GridOptionsBuilder) => GridOptions): this {
    getOptions ??= (builder: GridOptionsBuilder): GridOptions => builder.build();
    this.config.gridOptions = getOptions(this.gridOptionsBuilderFactory.getCrudBuilder(colDefs, gridIdentifier, data, this.rowActionButtons));

    if (AssertionUtils.isNullOrUndefined(this.config.rowData)) {
      this.config.gridOptions.rowData = data;
    }

    return this;
  }

  public withRowData(dataObservable: Observable<T[]>): this {
    this.config.dataObservable = dataObservable;
    return this;
  }

  public withExtraActions(extraActionConfig: CrudOverviewExtraActionConfig[]): this {
    this.config.extraActions = extraActionConfig;
    return this;
  }

  public withDefaultButtons(actionButtonsForGridSelection: CrudOverviewButtonConfig[]): this {
    this.config.buttons = actionButtonsForGridSelection;
    this.withRowActionButtons(actionButtonsForGridSelection);
    return this;
  }

  public withObjectSelection(selectedObjects: T[]): this {
    this.config.isObjectSelection = true;
    this.config.selectedObjects = selectedObjects;
    return this;
  }

  public withHeader(title: string): this {
    this.config.headerTitle = title;
    return this;
  }

  public withNewClicked(onClick: (event: MouseEvent) => void): this {
    this.config.withNewClicked = onClick;
    return this;
  }

  private withRowActionButtons(actionButtonsForGridSelection: CrudOverviewButtonConfig[]): void {
    actionButtonsForGridSelection?.forEach((actionButtonForGridSelection: CrudOverviewButtonConfig) => {
      if (actionButtonForGridSelection.title !== 'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.EXPORT') {
        this.rowActionButtons.push(actionButtonForGridSelection);
      }
    });
  }
}
