import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AssertionUtils} from '../../../common/utils/assertion-utils';
import {MenuTreeViewContextService} from '../menu-tree-view-context.service';
import {MenuItemEntry} from './menu-item-entry.interface';

@Component({
  selector: 'vdw-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent {
  @Input() public iconWidthInPx = 24;
  @Input() public isFavorites = false;
  @Input() public entry: MenuItemEntry;
  @Output() public menuItemClicked = new EventEmitter<PointerEvent>();

  public isPressed = false;

  public constructor(public readonly menuTreeViewContext: MenuTreeViewContextService) {}

  public hasChildEntries(): boolean {
    return !AssertionUtils.isEmpty(this.entry.childEntries);
  }

  public hasLink(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.entry.link);
  }

  public hasIcon(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.entry.icon) && this.entry.icon !== '' && !this.isFavorites;
  }

  public favoriteClicked(event: MouseEvent): void {
    event.stopPropagation();

    this.entry.isFavorited = !this.entry.isFavorited;
    this.menuTreeViewContext.itemFavorited.emit(this.entry);
  }
}
