import {ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, Optional, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {NavigationService} from '@application/services/navigation/navigation.service';
import {Subscription} from '@domain/profile/subscription';
import {AUTHENTICATION, Authentication} from '@infrastructure/http/authentication/authentication';
import {BackgroundJobsDialogComponent} from '@presentation/components/background-jobs/background-jobs-dialog.component';
import {
  ApplicationLayoutService,
  ArrowPosition,
  AssertionUtils,
  BaseComponent,
  DialogBuilder,
  DialogBuilderFactoryService,
  LocalStorageService,
  MenuItemEntry,
  MenuTreeViewContextService
} from '@vdw/angular-component-library';
import {filter, takeUntil} from 'rxjs';
import {AppComponent} from '../../../app.component';
import {AccountSwitcherDialogData} from '../account-switcher-dialog/account-switcher-dialog-data.interface';
import {AccountSwitcherDialogComponent} from '../account-switcher-dialog/account-switcher-dialog.component';
import {SubscriptionFavoritedItemsData} from './subscription-favorited-items-data.interface';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent extends BaseComponent implements OnInit, OnDestroy {
  public isDialog: boolean;
  public processActive: boolean;
  public subscriptionId: string;
  public subscriptionName: string;
  public processEntry: MenuItemEntry;
  public accountSwitcherActive: boolean;

  public readonly SIDEBAR_ICON_WIDTH_IN_PX = 16;
  private readonly ACCOUNT_SWITCH_WIDTH_MARGIN = 8;
  private readonly FAVORITED_KEY = 'favoritedItems';
  private readonly ACCOUNT_SWITCH_DIALOG_WIDTH = 240;

  private dialogBuilder: DialogBuilder;
  private subscriptionChanged: boolean;

  @ViewChild('processItem') public processItem: ElementRef<HTMLElement>;
  @ViewChild('accountSwitch') public accountSwitcher: ElementRef<HTMLElement>;

  public constructor(
    private readonly router: Router,
    private readonly localStorage: LocalStorageService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Optional() private readonly application: AppComponent,
    public readonly navigationService: NavigationService,
    private readonly menuTreeViewContext: MenuTreeViewContextService,
    public readonly applicationLayoutService: ApplicationLayoutService,
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    @Inject(MAT_DIALOG_DATA) private readonly data: AccountSwitcherDialogData
  ) {
    super();
    this.isDialog = !AssertionUtils.isNullOrUndefined(this.data.companies) && !AssertionUtils.isNullOrUndefined(this.data.subscriptions);
  }

  public ngOnInit(): void {
    const startSubscription = this.authentication.getCurrentSubscription();
    this.subscriptionId = startSubscription.id;
    this.subscriptionName = startSubscription.name;

    if (this.isDialog) {
      this.processEntry = this.navigationService.composeProcessItem();
      this.processEntry.shouldHide = !startSubscription.hasPermission(this.processEntry.permissionNumber);
      return;
    }

    this.initializeSubscription(startSubscription, false);

    this.authentication
      .currentSubscriptionChanges()
      .pipe(
        takeUntil(this.unSubscribeOnViewDestroy),
        filter((sub: Subscription) => sub.id !== startSubscription.id || this.subscriptionChanged)
      )
      .subscribe((subscription: Subscription) => {
        this.subscriptionChanged = true;
        this.initializeSubscription(subscription, true);
      });

    this.menuTreeViewContext.itemFavorited.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((entry: MenuItemEntry) => this.setFavorited(entry));
  }

  public initializeSubscription(subscription: Subscription, changes: boolean): void {
    this.subscriptionId = subscription.id;
    this.subscriptionName = subscription.name;

    this.processEntry = this.navigationService.composeProcessItem();
    this.processEntry.shouldHide = !subscription.hasPermission(this.processEntry.permissionNumber);

    if (!AssertionUtils.isEmpty(this.applicationLayoutService.menuItems) && !changes) {
      return;
    }

    this.applicationLayoutService.menuItems = this.navigationService.composeMenuItems(subscription);

    const flattenedItems = this.applicationLayoutService.getFlattenedMenuItems();
    const filteredFlattenedItems = flattenedItems?.filter((item: MenuItemEntry) => AssertionUtils.isNullOrUndefined(item.shouldHide)) ?? [];

    for (const entry of filteredFlattenedItems) {
      entry.shouldHide = !subscription.hasPermission(entry.permissionNumber);
    }

    this.processMenuItems(flattenedItems);

    if (changes) {
      const route = this.navigationService.getDefaultRoute(subscription);
      const active = flattenedItems.find((item: MenuItemEntry) => item.link === route);

      this.menuTreeViewContext.setActive(active);
      this.applicationLayoutService.getMenuItemPath(active)?.forEach((entry: MenuItemEntry) => (entry.isExpanded = true));
      this.setFavouriteItems(flattenedItems);
      return;
    }

    const possibleEntries = flattenedItems?.filter((entry: MenuItemEntry) => this.router.url?.startsWith(entry.link));

    if (!AssertionUtils.isEmpty(possibleEntries)) {
      const active = possibleEntries.reduce((first: MenuItemEntry, second: MenuItemEntry) => (this.router.url.split(first.link).length > this.router.url.split(second.link).length ? first : second));

      this.menuTreeViewContext.setActive(active);
      this.applicationLayoutService.getMenuItemPath(active)?.forEach((entry: MenuItemEntry) => (entry.isExpanded = true));
    }

    this.setFavouriteItems(flattenedItems);
  }

  public accountSwitcherClicked(): void {
    if (this.accountSwitcherActive) {
      this.dialogBuilder?.close();
      return;
    }

    this.accountSwitcherActive = true;
    this.dialogBuilder = this.dialogBuilderFactoryService.getBuilder();

    const sidebarWidth = this.isDialog ? this.ACCOUNT_SWITCH_DIALOG_WIDTH : this.localStorage.get<number>('sidebar-width');
    this.dialogBuilder = this.isDialog ? this.dialogBuilder.withoutBackdrop() : this.dialogBuilder.withCustomBackdrop('no-opacity');
    const data = this.isDialog ? {companies: this.data.companies, subscriptions: this.data.subscriptions} : this.application?.companyData;

    this.dialogBuilder
      .withWidth(`${sidebarWidth - this.ACCOUNT_SWITCH_WIDTH_MARGIN}px`)
      .withClass('bms-theme')
      .openAtElement(this.accountSwitcher.nativeElement, ArrowPosition.TOP, AccountSwitcherDialogComponent, data, this.changeDetectorRef, false, false, 0)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(() => (this.accountSwitcherActive = false));
  }

  public processClicked(): void {
    if (this.processActive) {
      this.dialogBuilder?.close();
      return;
    }

    this.processActive = true;
    this.dialogBuilder = this.dialogBuilderFactoryService.getBuilder();
    this.dialogBuilder = this.isDialog ? this.dialogBuilder.withoutBackdrop() : this.dialogBuilder;

    this.dialogBuilder
      .openAtElement(this.processItem.nativeElement, ArrowPosition.LEFT, BackgroundJobsDialogComponent, {}, this.changeDetectorRef)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(() => (this.processActive = false));
  }

  public setFavorited(item: MenuItemEntry): void {
    const favorites = this.applicationLayoutService.menuItems[0].childEntries;

    if (favorites.includes(item) && !item.isFavorited) {
      this.applicationLayoutService.menuItems[0].childEntries = favorites.filter((entry: MenuItemEntry) => item.title !== entry.title);
    } else if (!favorites.includes(item) && item.isFavorited) {
      favorites.push(item);
    }

    this.applicationLayoutService.hideFavorites = AssertionUtils.isEmpty(this.applicationLayoutService.menuItems[0].childEntries);

    const storedFavorites = this.localStorage.get<SubscriptionFavoritedItemsData[]>(this.FAVORITED_KEY);

    if (!AssertionUtils.isNullOrUndefined(storedFavorites) && storedFavorites.length > 0) {
      const currentFavorites = storedFavorites.find((favoritedItem: SubscriptionFavoritedItemsData) => favoritedItem.subscriptionId === this.subscriptionId);

      if (!AssertionUtils.isNullOrUndefined(currentFavorites)) {
        currentFavorites.favoritedItems = this.applicationLayoutService.menuItems[0].childEntries;
        this.localStorage.set(this.FAVORITED_KEY, storedFavorites);
      } else {
        storedFavorites.push({subscriptionId: this.subscriptionId, favoritedItems: this.applicationLayoutService.menuItems[0].childEntries});
        this.localStorage.set(this.FAVORITED_KEY, storedFavorites);
      }
    } else {
      this.localStorage.set(this.FAVORITED_KEY, [{subscriptionId: this.subscriptionId, favoritedItems: this.applicationLayoutService.menuItems[0].childEntries}]);
    }
  }

  public ngOnDestroy(): void {
    this.dialogBuilder?.close();
    super.ngOnDestroy();
  }

  private processMenuItems(entries: MenuItemEntry[]): void {
    this.processEmptyNavigationItems(entries);
    this.processSingularNestedItems(entries);
    this.processEmptyNavigationItems(entries);
  }

  private processEmptyNavigationItems(entries: MenuItemEntry[]): void {
    const emptyItems = entries.filter((entry: MenuItemEntry) => (AssertionUtils.isNullOrUndefined(entry.link) && entry.childEntries?.every((child: MenuItemEntry) => child.shouldHide)) ?? false);

    for (const emptyItem of emptyItems) {
      const parent = this.applicationLayoutService.findParentEntry(emptyItem);

      if (!AssertionUtils.isNullOrUndefined(parent)) {
        parent.childEntries = parent.childEntries.filter((child: MenuItemEntry) => child.title !== emptyItem.title);
      }
    }
  }

  private processSingularNestedItems(entries: MenuItemEntry[]): void {
    const singularNestedItems = entries?.filter(
      (entry: MenuItemEntry) =>
        !AssertionUtils.isNullOrUndefined(this.applicationLayoutService.findParentEntry(entry)) &&
        entry.childEntries?.filter((child: MenuItemEntry) => child.shouldHide === false && !AssertionUtils.isNullOrUndefined(child.link))?.length === 1
    );

    if (AssertionUtils.isEmpty(singularNestedItems)) {
      return;
    }

    for (const singularNestedItem of singularNestedItems) {
      const parent = this.applicationLayoutService.findParentEntry(singularNestedItem);
      const item = singularNestedItem.childEntries.find((entry: MenuItemEntry) => entry.shouldHide === false);

      const index = parent?.childEntries?.findIndex((entry: MenuItemEntry) => entry.link === singularNestedItem.link);
      const rootIndex = this.applicationLayoutService.menuItems.findIndex((entry: MenuItemEntry) => entry.title === singularNestedItem.title);

      if (!AssertionUtils.isNullOrUndefined(parent) && index !== -1) {
        singularNestedItem.childEntries.forEach((childItem: MenuItemEntry) => (childItem.shouldHide = true));
        singularNestedItem.childEntries = [];
        singularNestedItem.link = item.link;
        singularNestedItem.title = item.title;
      } else if (rootIndex !== -1) {
        this.applicationLayoutService.menuItems[rootIndex].childEntries.forEach((childItem: MenuItemEntry) => (childItem.shouldHide = true));
        this.applicationLayoutService.menuItems[rootIndex].childEntries = [];
        this.applicationLayoutService.menuItems[rootIndex].link = item.link;
        this.applicationLayoutService.menuItems[rootIndex].title = item.title;
      }
    }
  }

  private setFavouriteItems(flattenedItems: MenuItemEntry[]): void {
    const storedFavorites = this.localStorage.get<SubscriptionFavoritedItemsData[]>(this.FAVORITED_KEY) ?? [];

    if (!AssertionUtils.isEmpty(storedFavorites) && Array.isArray(storedFavorites)) {
      const currentFavorites = storedFavorites.find((favoritedItem: SubscriptionFavoritedItemsData) => favoritedItem.subscriptionId === this.subscriptionId);

      if (!AssertionUtils.isNullOrUndefined(currentFavorites) && currentFavorites.favoritedItems.length > 0) {
        const favoriteLinks = currentFavorites.favoritedItems.map((item: MenuItemEntry) => item.link);
        const favorites = flattenedItems.filter((item: MenuItemEntry) => favoriteLinks.includes(item.link) && item.shouldHide === false);
        favorites.forEach((item: MenuItemEntry) => (item.isFavorited = true));

        this.applicationLayoutService.menuItems[0].childEntries = favorites;

        this.applicationLayoutService.hideFavorites = AssertionUtils.isEmpty(favorites);
      } else {
        this.applicationLayoutService.hideFavorites = true;
      }
    }
  }
}
