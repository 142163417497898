import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {MAT_RIPPLE_GLOBAL_OPTIONS} from '@angular/material/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ActivatedRouteSnapshot, Router, RouteReuseStrategy, RouterModule} from '@angular/router';
import {ServiceWorkerModule} from '@angular/service-worker';
import {GetPossiblePropertyValuesOfList} from '@application/command/get-possible-property-values-of-list';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {HttpRuntimeApplicationEnvironmentService} from '@application/configuration/http-runtime-application-environment.service';
import {
  initializeApplicationEnvironment,
  initializeGridOptionsBuilderFactory,
  InitializeL10n,
  initializeMSALInstance,
  initializeNavigationHistory,
  initializeSignalrHubConnectionFactory
} from '@application/configuration/initialize-application';
import {RUNTIME_APPLICATION_ENVIRONMENT} from '@application/configuration/runtime-application-environment';
import {ErrorMessageHelper} from '@application/helper/error-message-helper';
import {CustomRouteReuseStrategy} from '@application/helper/routing/custom-route-reuse-strategy';
import {NavigationHistoryService} from '@application/navigation-history/navigation-history.service';
import {BadgeLabelPipe} from '@application/pipes/badge-label.pipe';
import {MSAL_INSTANCE, MsalBroadcastService, MsalModule, MsalRedirectComponent, MsalService} from '@azure/msal-angular';
import {environment} from '@environments/environment';
import {AUTHENTICATION} from '@infrastructure/http/authentication/authentication';
import {HttpAuthenticationService} from '@infrastructure/http/authentication/http-authentication.service';
import {BACKGROUND_JOBS} from '@infrastructure/http/background-jobs/background-jobs';
import {HttpBackgroundJobsService} from '@infrastructure/http/background-jobs/http-background-jobs.service';
import {DRAWINGS} from '@infrastructure/http/drawing/drawings';
import {HttpDrawingsService} from '@infrastructure/http/drawing/http-drawings.service';
import {HttpGridSettingsService} from '@infrastructure/http/grid-settings/http-grid-settings.service';
import {InterceptedHttp} from '@infrastructure/http/intercepted.http';
import {HttpProfileService} from '@infrastructure/http/profile/http-profile.service';
import {PROFILE} from '@infrastructure/http/profile/profile';
import {ONBOARDING} from '@infrastructure/onboarding/onboarding';
import {OnboardingService} from '@infrastructure/onboarding/onboarding.service';
import {SIGNALR_EVENT} from '@infrastructure/signalr/events/signalr-event.interface';
import {SignalrEventService} from '@infrastructure/signalr/events/signalr-event.service';
import {REALTIME_BACKGROUND_JOBS} from '@infrastructure/signalr/jobs/realtime-background-jobs';
import {SignalrBackgroundJobService} from '@infrastructure/signalr/jobs/signalr-background-job.service';
import {REALTIME_SUBSCRIPTIONS} from '@infrastructure/signalr/subscription/realtime-subscriptions';
import {SignalrSubscriptionService} from '@infrastructure/signalr/subscription/signalr-subscription.service';
import {ConnectivityToastModule} from '@presentation/components/connectivity-toast/connectivity-toast.module';
import {LoggerComponent} from '@presentation/components/logger/logger.component';
import {NewUpdateAvailableComponent} from '@presentation/components/new-update-available/new-update-available.component';
import {AccountSwitcherDialogComponent} from '@presentation/navigation/account-switcher-dialog/account-switcher-dialog.component';
import {MobileNavigationSidebarComponent} from '@presentation/navigation/mobile-navigation/mobile-navigation-sidebar.component';
import {NavigationMobileComponent} from '@presentation/navigation/mobile-navigation/navigation-mobile.component';
import {NavigationComponent} from '@presentation/navigation/navigation-component/navigation.component';
import {NavigationHeaderComponent} from '@presentation/navigation/navigation-header/navigation-header.component';
import {NavigationHoverAreaComponent} from '@presentation/navigation/navigation-hover-area/navigation-hover-area.component';
import {NavigationResizerComponent} from '@presentation/navigation/navigation-resizer/navigation-resizer.component';
import {NotificationsModule} from '@presentation/pages/notifications/notifications.module';
import {NotificationsService} from '@presentation/pages/notifications/notifications.service';
import {UnauthorizedPageComponent} from '@presentation/pages/status-codes/401/unauthorized-page.component';
import {NotFoundPageComponent} from '@presentation/pages/status-codes/404/not-found-page.component';
import {SharedModule} from '@shared/shared.module';
import {
  AccountSwitchModule,
  ApplicationLayoutModule,
  ENVIRONMENT,
  GlobalErrorHandler,
  GridOptionsBuilderFactoryService,
  LanguageService,
  LocalStorageService,
  MenuTreeViewModule,
  ReloadRequiredModule,
  ResizeElementModule,
  SignalrHubConnectionFactoryService,
  SignalrLogger,
  TranslateService,
  TranslationHandler,
  TranslationLoader,
  WINDOW_PROVIDERS
} from '@vdw/angular-component-library';
import {L10nIntlModule, L10nTranslationModule} from 'angular-l10n';
import {map} from 'lodash-es';
import {INGXLoggerConfig, LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {AppComponent} from './app.component';
import {externalUrlProvider, routes} from './app.routing';

const loggerConfig: INGXLoggerConfig = {
  level: environment.production ? NgxLoggerLevel.WARN : NgxLoggerLevel.TRACE,
  serverLogLevel: NgxLoggerLevel.OFF
};

@NgModule({
  declarations: [
    LoggerComponent,
    AppComponent,
    MobileNavigationSidebarComponent,
    NavigationComponent,
    NavigationMobileComponent,
    NavigationHeaderComponent,
    AccountSwitcherDialogComponent,
    UnauthorizedPageComponent,
    NotFoundPageComponent,
    NewUpdateAvailableComponent,
    BadgeLabelPipe,
    NavigationHoverAreaComponent,
    NavigationResizerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'}),
    L10nIntlModule,
    L10nTranslationModule.forRoot(
      {
        format: 'language',
        defaultLocale: {language: LanguageService.DEFAULT_LANGUAGE},
        keySeparator: '.',
        providers: [{name: 'app', asset: './assets/i18n'}],
        schema: map(LanguageService.AVAILABLE_LANGUAGES, (language: string) => {
          return {locale: {language}};
        })
      },
      {
        loader: InitializeL10n,
        translationLoader: TranslationLoader,
        translationHandler: TranslationHandler
      }
    ),
    ReloadRequiredModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production || environment.development}),
    ConnectivityToastModule,
    LoggerModule.forRoot(loggerConfig),
    NotificationsModule,
    MenuTreeViewModule,
    AccountSwitchModule,
    ApplicationLayoutModule,
    MsalModule,
    ResizeElementModule
  ],
  providers: [
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy},
    {provide: RUNTIME_APPLICATION_ENVIRONMENT, useClass: HttpRuntimeApplicationEnvironmentService},
    {provide: AUTHENTICATION, useClass: HttpAuthenticationService},
    {provide: PROFILE, useClass: HttpProfileService},
    {provide: SIGNALR_EVENT, useClass: SignalrEventService},
    {provide: ONBOARDING, useClass: OnboardingService},
    {provide: BACKGROUND_JOBS, useClass: HttpBackgroundJobsService},
    {provide: REALTIME_BACKGROUND_JOBS, useClass: SignalrBackgroundJobService},
    {provide: REALTIME_SUBSCRIPTIONS, useClass: SignalrSubscriptionService},
    {provide: DRAWINGS, useClass: HttpDrawingsService},
    {provide: MAT_DIALOG_DATA, useValue: {}},
    GetPossiblePropertyValuesOfList,
    BackendConfiguration,
    ErrorMessageHelper,
    NotificationsService,
    NavigationHistoryService,
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot): void => {
        const externalUrl = route.data['externalUrl'];
        window.open(externalUrl, '_blank');
      }
    },
    WINDOW_PROVIDERS,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApplicationEnvironment,
      multi: true,
      deps: [RUNTIME_APPLICATION_ENVIRONMENT]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeNavigationHistory,
      multi: true
    },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: {disabled: true, animation: {enterDuration: 0, exitDuration: 0}, centered: true}
    },
    {provide: ENVIRONMENT, useValue: environment},
    {
      provide: SignalrHubConnectionFactoryService,
      useFactory: initializeSignalrHubConnectionFactory,
      deps: [BackendConfiguration, AUTHENTICATION, SignalrLogger]
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {floatLabel: 'always'}
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: initializeMSALInstance,
      deps: [BackendConfiguration]
    },
    MsalService,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptedHttp,
      multi: true
    },
    {
      provide: GridOptionsBuilderFactoryService,
      useFactory: initializeGridOptionsBuilderFactory,
      deps: [TranslateService, LocalStorageService, Router, HttpGridSettingsService]
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
