import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnInit, Optional, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AgGridAngular} from 'ag-grid-angular';
import {RowNode} from 'ag-grid-community';
import {takeUntil} from 'rxjs';
import {BaseComponent} from '../../../base-component';
import {AssertionUtils} from '../../../common/utils/assertion-utils';
import {DialogBuilder} from '../../../dialogs/dialog-builder/dialog-builder';
import {DialogBuilderFactoryService} from '../../../dialogs/dialog-builder/dialog-builder-factory.service';
import {ArrowPosition} from '../../../dialogs/dialog-reposition/arrow-position.enum';
import {NoDataOverlayComponent} from '../../../grids/overlay/no-data-overlay/no-data-overlay.component';
import {TranslateService} from '../../../translation/translate.service';
import {GridSelectionOptionsComponent} from '../../grid-selection-options/grid-selection-options.component';
import {CrudOverviewDataConfig} from '../interfaces/crud-overview-data-config.interface';

@Component({
  selector: 'vdw-crud-overview-data-page',
  templateUrl: './crud-overview-data-page.component.html',
  styleUrls: ['./crud-overview-data-page.component.scss']
})
export class CrudOverviewDataPageComponent extends BaseComponent implements AfterViewInit, OnInit {
  @Input() public config: CrudOverviewDataConfig<any> = {};

  @ViewChild('grid') public grid: AgGridAngular;
  @ViewChild('container') public container: ElementRef<HTMLDivElement>;
  @ViewChild('gridContainer') public gridContainer: ElementRef<HTMLDivElement>;

  public showSelected: string;
  public selectedItemCount = 0;
  public dynamicGridHeight: number;
  public noRowsOverlayComponent = NoDataOverlayComponent;
  public noRowsOverlayComponentParams = {};

  private gridRect: DOMRect;
  private containerRect: DOMRect;

  private selectOptionsOpen = false;
  private dialogBuilder: DialogBuilder;

  private readonly DIALOG_VERTICAL_OFFSET = 28;

  public constructor(
    private readonly dialogBuilderFactory: DialogBuilderFactoryService,
    private readonly changeDetector: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) @Optional() data: any,
    private readonly translate: TranslateService,
    @Optional() private readonly dialogRef: MatDialogRef<CrudOverviewDataPageComponent>
  ) {
    super();

    this.config = data?.config;
  }

  public get onlyShowSelected(): boolean {
    return this.config.onlyShowSelected;
  }

  public ngOnInit(): void {
    this.config.dataObservable.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((data: any[]) => (this.config.rowData = data));

    this.dialogBuilder = this.dialogBuilderFactory.getBuilder();
    this.showSelected = this.translate.instant('ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.ONLY_SHOW_SELECTED') + ':';
    this.noRowsOverlayComponentParams = {scale: 0.7, titleParam: this.config.headerTitle ?? this.translate.instant('ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.DATA'), hideDescription: true};
  }

  public ngAfterViewInit(): void {
    this.gridRect = this.gridContainer.nativeElement.getBoundingClientRect();
    this.containerRect = this.container.nativeElement.getBoundingClientRect();

    if (this.config.isObjectSelection) {
      this.grid.api.forEachNode((node: RowNode) => node.setSelected(this.config.selectedObjects.some((selectedObject: any) => selectedObject?.id === node.data?.id)));
    }

    this.setDynamicDomLayout();
    this.grid.rowDataUpdated.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => this.setDynamicDomLayout());

    this.grid?.rowSelected?.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => {
      this.selectedItemCount = this.grid?.api.getSelectedRows()?.length ?? 0;
      this.gridRect = this.gridContainer.nativeElement.getBoundingClientRect();
      const selectedItemCountLength = this.selectedItemCount > 0 ? true : false;

      if (this.onlyShowSelected) {
        this.toggleShowOnlySelected(selectedItemCountLength);
      }

      if (this.config.isObjectSelection) {
        return;
      }

      const top = this.gridRect.top - this.DIALOG_VERTICAL_OFFSET;

      if (this.selectedItemCount !== 0 && !this.selectOptionsOpen) {
        this.selectOptionsOpen = true;
        this.dialogBuilder
          .withoutBackdrop()
          .withAutoWidth()
          .withRefData({grid: this.grid, config: this.config})
          .openAtPosition(this.gridRect.left, top, ArrowPosition.LEFT, GridSelectionOptionsComponent, {}, this.changeDetector, false)
          .subscribe();
      } else if (this.selectedItemCount === 0) {
        this.dialogBuilder.close();
        this.selectOptionsOpen = false;
      }
    });
  }

  public hasNewClickedEvent(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.config.withNewClicked);
  }

  public cancel(): void {
    this.dialogRef?.close();
  }

  public exportData(): void {
    this.grid.api.exportDataAsCsv();
  }

  public select(): void {
    this.dialogRef?.close(this.grid?.api?.getSelectedRows() ?? []);
  }

  public toggleShowOnlySelected(checked: boolean): void {
    this.config.onlyShowSelected = checked;

    if (this.config.gridOptions.rowModelType === 'serverSide') {
      this.grid.api.onFilterChanged();
    } else {
      const data = AssertionUtils.isNullOrUndefined(this.config.rowData) ? this.config.gridOptions.rowData : this.config.rowData;
      checked ? this.grid.api.setGridOption('rowData', this.grid?.api?.getSelectedRows()) : this.grid.api.setGridOption('rowData', data);
    }
  }

  private setDynamicDomLayout(): void {
    const size = this.grid.api.getSizesForCurrentTheme();
    const displayedRows = this.grid.api.getDisplayedRowCount();

    const calculatedGridHeight = displayedRows * size.rowHeight + size.headerHeight * 2;
    const maxHeight = this.containerRect.height - (this.gridRect.top - this.containerRect.top);

    if (calculatedGridHeight > maxHeight && displayedRows > 0 && maxHeight !== 0) {
      this.grid.api.setGridOption('domLayout', 'normal');
      this.dynamicGridHeight = null;
    } else {
      this.grid.api.setGridOption('domLayout', 'autoHeight');
      this.dynamicGridHeight = calculatedGridHeight;
    }

    this.changeDetector.detectChanges();
    this.grid.api.sizeColumnsToFit();
  }
}
